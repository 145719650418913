import { ChallengeType } from './challenges.type';

export type CreateQuizType = {
  challenges: ChallengeType[] | null;
  title_EN: string;
  title_HU: string;
  scoreForCorrect: number;
  challengeIds: string[];
  questions: QuestionType[];
};

export type QuestionType = {
  id?: string;
  text_EN: string;
  text_HU: string;
  answers: AnswerType[];
  photo?: File;
  photoUrl?: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type AnswerType = {
  id?: string;
  text_EN: string;
  text_HU: string;
  isCorrect: boolean;
  createdAt?: Date;
  updatedAt?: Date;
};

export type QuizType = {
  id: string;
  title_EN: string;
  title_HU: string;
  scoreForCorrect: number;
  createdAt?: Date;
  updatedAt?: Date;
  challenges: ChallengeType[];
  questions: QuestionType[];
};

export function createQuizToFormData(quiz: CreateQuizType): FormData {
  const formData = new FormData();

  formData.append('quizData', JSON.stringify(quiz));

  quiz.questions.forEach((question, index) => {
    if (question.photo) {
      formData.append(`photo_${index}`, question.photo);
    }
  });

  return formData;
}
