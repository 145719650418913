import { Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, styled, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import SnackbarContext from 'contexts/snackbarContext';
import { createImpactReport, getImpactReportById } from 'services/impactReport.services';

import { addMonths, endOfDay, lastDayOfMonth, startOfDay, startOfMonth } from 'date-fns';
import DatePicker from 'react-date-picker';
import { theme } from 'theme';
import { CreateImpactReportType } from 'types/impactReport.type';
import { ImpactReportModelType } from 'types/impactReportModel.type';
import { ImpactReportTemplateType } from 'types/impactReportTemplate.type';
import { OrganisationType } from 'types/organisations.type';
/*
import { OrganisationType } from 'types/organisations.type';
import { ImpactReportTemplateType } from 'types/impactReportTemplate.type';
import { ImpactReportModelType } from 'types/impactReportModel.type';
*/

type CreateImpactReportFormType = {
  organisation: string;
  template: string;
  model: string;
  dateFrom: Date;
  dateTo: Date;
};

const CustomForm = styled('form')`width: 100%;`;

interface CreateImpactReportFormProps {
  impactReportId?: string;
  organisationList: OrganisationType[];
  impactReportTemplateList: ImpactReportTemplateType[];
  impactReportModelList: ImpactReportModelType[];
}

export default function CreateImpactReportForm({impactReportId, organisationList, impactReportTemplateList, impactReportModelList} : CreateImpactReportFormProps) {
  const { t } = useTranslation();
  const {
    handleSubmit, 
    formState: { errors }, 
    setValue, 
    control, 
    watch,
  } = useForm<CreateImpactReportFormType>();
  const { handleOpen } = useContext(SnackbarContext);
  
  const { id } = useParams();
  const navigate = useNavigate();


  /*
  const watchStartDate = watch('dateFrom');
  const watchEndDate = watch('dateTo');
  */
 
  useEffect(() => {

    if (id) {
      getImpactReportById(id).then((data) => {
        
        //if (data.organisation) {
        setValue('organisation', data.organisation.id);
        setValue('template', data.template.id);
        setValue('model', data.model.id);
        setValue('dateFrom', data.dateFrom);
        setValue('dateTo', data.dateTo);
        //}

      });
    }
  }, [id]);

  const onSubmit = (data: CreateImpactReportFormType) => {
    
    const impactReprotObject: CreateImpactReportType = {
      organisationId: data.organisation,
      templateId: data.template,
      modelId: data.model,
      dateFrom: data.dateFrom,
      dateTo: data.dateTo
    };

    console.log(impactReprotObject);

    if (!id) {
      createImpactReport(impactReprotObject)
        .then((result) => {
          navigate('/impactReports/'+result.data.id);
          handleOpen(t('impactReportForm.impactReportCreatedSuccessfullyMessage'), 'success',);
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });

    }
  };

  const checkKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  return (
    <Card sx={{ padding: '40px', maxWidth: '800px' }}>
      <CustomForm onSubmit={handleSubmit(onSubmit)} onKeyDown={checkKeyDown}>
        <Grid container spacing={2}>


          {/* Date fields */}
          <Grid item xs={6}>
            <Typography style={{ fontSize: 12, color: theme.palette.grey[600] }}>{t('formFields.dateFrom')}</Typography>
            <Controller
              control={control}
              name="dateFrom"
              defaultValue={startOfMonth( addMonths( startOfDay(new Date()),-1))}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value ? startOfDay(value as Date) : value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ fontSize: 12, color: theme.palette.grey[600] }}>{t('formFields.dateTo')}</Typography>
            <Controller
              control={control}
              name="dateTo"
              defaultValue={lastDayOfMonth(addMonths( endOfDay(new Date()),-1))}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value ? endOfDay(value as Date) : value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
          


          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="label">{t('formFields.organisation')}</InputLabel>
              <Controller
                name="organisation"
                control={control}
                //defaultValue={impactReportId}
                
                render={({ field: { onChange, value } }) => (
                  <Select value={value} onChange={onChange} label={t('formFields.organisation')} labelId="label" error={errors.organisation ? true : false} disabled={!!impactReportId}>
                    <MenuItem></MenuItem>
                    {organisationList.map((organisation: OrganisationType) => {return (<MenuItem key={organisation?.id}value={organisation?.id}>{organisation?.companyName}</MenuItem>);})}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>



          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="label">{t('formFields.template')}</InputLabel>
              <Controller
                name="template"
                control={control}
                //defaultValue={impactReportId}
                defaultValue={'77d66fd9-0fd8-4517-a3e3-a547a16809a4'}
                render={({ field: { onChange, value } }) => (
                  <Select value={value} onChange={onChange} label={t('formFields.template')} labelId="label" error={errors.template ? true : false} disabled={!!impactReportId}>
                    <MenuItem></MenuItem>
                    {impactReportTemplateList.map((impactReportTemplate: ImpactReportTemplateType) => {return (<MenuItem key={impactReportTemplate?.id}value={impactReportTemplate?.id}>{impactReportTemplate?.name}</MenuItem>);})}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="label">{t('formFields.model')}</InputLabel>
              <Controller
                name="model"
                control={control}
                defaultValue={'7922c471-2820-4d80-99c0-d35a00d1888c'}
                disabled={true}
                render={({ field: { onChange, value } }) => (
                  <Select value={value} onChange={onChange} label={t('formFields.model')} labelId="label" error={errors.model ? true : false} disabled={!!impactReportId}>
                    <MenuItem></MenuItem>
                    {impactReportModelList.map((impactReportModel: ImpactReportModelType) => {return (<MenuItem key={impactReportModel?.id}value={impactReportModel?.id}>{impactReportModel?.name}</MenuItem>);})}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>





        </Grid>

        <Button variant="contained" sx={{ marginTop: '20px' }} type="submit">{t('formFields.submitButtonLabel')}</Button>
      </CustomForm>
    </Card>
  );
}
